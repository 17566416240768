<template>
  <div id="TheFooter" class="container-fluid bg-color-primary-1">
    <div class="container">
      <p class="text-center text-white m-0 p-0">
        تمام حقوق مادی و معنوی محفوظ است.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
